<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >curl请求在线生成</h2>
	<div style="margin-top: 4%;">
			<el-col :sm="17" style="margin: 0 auto;">
				<el-form :model="httpData" label-position="right" label-width="100px" ref="form">
					<el-row :gutter="20">
						<el-col :sm="6">
							<el-form-item label="请求方式">
								<el-select v-model="httpData.request" @change="requestChange()">
								      <el-option label="GET"  value="GET"></el-option>
								      <el-option label="POST" value="POST"></el-option>
									  <el-option label="PUT" value="PUT"></el-option>
									  <el-option label="DELETE" value="DELETE"></el-option>
								</el-select>
							
							</el-form-item>
						</el-col>
						<el-col :sm="18">
							<el-form-item label="请求URL">
								<el-input v-model="httpData.url" placeholder="请求url" @input="generate()"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row :gutter="20">
						<el-col :sm="6">
							<el-form-item  label="编码方式">
								<el-select v-model="httpData.encoded" @change="generate()">
									  <el-option label="UTF-8" value="UTF-8"></el-option>
									  <el-option label="GBK" value="GBK"></el-option>
									  <el-option label="GB2312" value="GB2312"></el-option>
									  <el-option label="Unicode" value="Unicode"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						
						<el-col :sm="18">
							<el-form-item  label="ContentType" >
								<el-select v-model="httpData.contentType" placeholder="Content-Type" style="width:100%" @change="generate()">
									  <el-option label="application/x-www-form-urlencoded" value="application/x-www-form-urlencoded"></el-option>
								      <el-option label="application/json" value="application/json"></el-option>
									  <el-option label="multipart/form-data" value="multipart/form-data"></el-option>
									  <el-option label="text/xml" value="text/xml"></el-option>
								    </el-select>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row :gutter="20">
						<el-col :sm="8">
							<el-form-item label="请求配置">
								<el-checkbox-group v-model="httpData.type" @change="typeChange()">
								  <el-checkbox-button label="1" >Header</el-checkbox-button>
								  <el-checkbox-button label="2" >BasicAuth</el-checkbox-button>
								  <el-checkbox-button label="3" >Cookie</el-checkbox-button>
								</el-checkbox-group>
							</el-form-item>
						</el-col>
						
						<el-col :sm="16">
							<el-form-item label="请求参数">
							    <el-checkbox-group v-model="httpData.parameter" @change="generate()">
							      <el-checkbox-button label="1" >显示响应头(I)</el-checkbox-button>
							      <el-checkbox-button label="2" >显示请求过程(v)</el-checkbox-button>
							      <el-checkbox-button label="3" >结果保存本地(O)</el-checkbox-button>
							    </el-checkbox-group>
							  </el-form-item>
						</el-col>
					</el-row>
					
					<div v-for="(item,index) in httpData.header" :key="index">
						<el-row :gutter="20" v-if="httpData.headerShow">
							<el-col :sm="12">
								<el-form-item >
									<el-input v-model="item.key" type="text" placeholder="Header Key" @input="headerInput(index)"></el-input>
								</el-form-item>
							</el-col>
							<el-col :sm="10">
								<el-form-item >
									<el-input v-model="item.value" type="text" placeholder="Header Value" @input="headerInput(index)"></el-input>
								</el-form-item>
							</el-col>
							<el-col :sm="2" v-if="index!=0">
									<el-button type="danger" @click="deleteInput(index)" >删除</el-button>
							</el-col>
						</el-row>
					</div>
					
					<el-row :gutter="20" v-if="httpData.authShow">
						<el-col :sm="12">
							<el-form-item label="UserName">
								<el-input v-model="httpData.user" type="text" placeholder="用户名" @input="generate()" ></el-input>
							</el-form-item>
						</el-col>
						<el-col :sm="12">
							<el-form-item label="PassWord">
								<el-input v-model="httpData.password" type="text" placeholder="密码" @input="generate()"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row :gutter="20" v-if="httpData.cookieShow">
						<el-col :sm="24">
							<el-form-item  label="Cookie">
								<el-input v-model="httpData.cookie" type="textarea" :rows="3"  @input="generate()"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
						
					<el-row :gutter="20" v-if="httpData.bodyDataShow">
						<el-col :sm="24">
							<el-form-item  label="Body">
								<el-input v-model="httpData.bodyData" type="textarea" :rows="3" placeholder="请求数据" @input="generate()"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row :gutter="20">
						<el-col :sm="24">
							<el-form-item  label="Curl命令">
								<el-input v-model="httpData.curl" type="textarea" :rows="3" readonly placeholder="生成结果"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<div style="margin: 0 auto; text-align: center;">
						<el-button  @click="init()" type="danger" plain>还原</el-button>
						<el-button  @click="copy(httpData.curl)" type="primary" plain>复制</el-button>
					</div>
					<el-row>
					  <section class="content element-doc content">
						  <h3 id="ji-ben-yong-fa">curl介绍</h3>
						  <p>
							curl 是非常强大的命令行工具库，用于通过 URL 传输数据。它的名字就是客户端（client）的 URL 工具的意思（command line tool and library for transferring data with URLs）。
							它的功能非常强大，命令行参数多达几十种。curl 是免费的开源软件，它支持包括 FTP、HTTP、HTTPS、FTP、SCP，SFTP 数十种协议。
						  </p>
					  </section>
					</el-row>
					<el-row>
						  <h3 id="ji-ben-yong-fa">参数说明</h3>
						  <el-table :data="httpData.tableData" style="width: 100% background: oldlace" :row-class-name="warning-row">
							  <el-table-column prop="number" label="序号" width="80"></el-table-column>
							  <el-table-column prop="parm" label="参数" width="150"></el-table-column>
							  <el-table-column prop="depict" label="使用说明"></el-table-column>
							  <el-table-column prop="instance" label="参考样例"></el-table-column>
							</el-table>
					</el-row>
				</el-form>
		</el-col>
	</div>
</template>

<script setup>
import {ref} from 'vue'
import {clipboard as copy} from '../../common/actionrecord'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
	let httpData = ref({
		moduleid:2008,
	    url: "",
	    request: "GET",
	    encoded: "UTF-8",
		contentType: "text/xml",
		parameter: ["1"],
		type: ["1"],
		headerShow: true,
		header: [{}],
		bodyData: "",
		bodyDataShow: true,
		curlRequest: "",
		user: "",
		password: "",
		cookie: "",
		authShow: false,
		cookieShow: false,
		tableData: [{
			          number: '1',
			          parm: '-A',
			          depict: '-A参数指定客户端的用户代理标头，即User-Agent。curl 的默认用户代理字符串是curl/[version]。',
					  instance:"curl -A 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/76.0.3809.100 Safari/537.36' https://google.com",
			        }, {
			          number: '2',
			          parm: '-b',
			          depict: '-b参数用来向服务器发送 Cookie。',
					  instance:"curl -b 'foo1=bar' -b 'foo2=baz' https://google.com",
			        }, {
			          number: '3',
			          parm: '-c',
			          depict: '-c参数将服务器设置的 Cookie 写入一个文件',
					  instance:"curl -c cookies.txt https://www.google.com",
			        }, {
			          number: '4',
			          parm: '-d',
			          depict: '-d参数用于发送 POST 请求的数据体',
					  instance:"curl -d'login=emma＆password=123'-X POST https://google.com/login",
			        }, {
			          number: '5',
			          parm: '--data-urlencode',
			          depict: '--data-urlencode参数等同于-d，发送 POST 请求的数据体，区别在于会自动将发送的数据进行 URL 编码。',
					  instance:"curl --data-urlencode 'comment=hello world' https://google.com/login",
			        },
					 {
					  number: '6',
					  parm: '-e',
					  depict: 'e参数用来设置 HTTP 的标头Referer，表示请求的来源',
					  instance:"curl -e 'https://google.com?q=example' https://www.example.com",
					},
					{
					  number: '7',
					  parm: '-F',
					  depict: "-F参数用来向服务器上传二进制文件。类型及文件名,可不做指定",
					  instance:" curl -F 'file=@photo.png;type=image/png;filename=me.png' https://google.com/profile",
					},
					{
					  number: '8',
					  parm: '-G',
					  depict: '-G参数用来构造 URL 的查询字符串。实际请求的 URL 为https://google.com/search?q=kitties&count=20。如果省略--G，会发出一个 POST 请求',
					  instance: "curl -G -d 'q=kitties' -d 'count=20' https://google.com/search",
					},
					{
					  number: '9',
					  parm: '-H',
					  depict: '-H参数添加 HTTP 请求的标头。',
					  instance: "curl -H 'Accept-Language: en-US' https://google.com",
					},
					{
					  number: '10',
					  parm: '-i',
					  depict: '-i参数打印出服务器回应的 HTTP 标头。-I参数向服务器发出 HEAD 请求，然会将服务器返回的 HTTP 标头打印出来。--head参数等同于-I',
					  instance: "curl -i https://www.example.com",
					},
					{
					  number: '11',
					  parm: '-k',
					  depict: '-k参数指定跳过 SSL 检测。',
					  instance: "curl -k https://www.example.com",
					},
					{
					  number: '12',
					  parm: '-L',
					  depict: '-L参数会让 HTTP 请求跟随服务器的重定向。curl 默认不跟随重定向。',
					  instance: "curl -L -d 'tweet=hi' https://api.twitter.com/tweet",
					},
					{
					  number: '13',
					  parm: '--limit-rate',
					  depict: '--limit-rate用来限制 HTTP 请求和回应的带宽，模拟慢网速的环境。将带宽限制在每秒 200K 字节。',
					  instance: "curl --limit-rate 200k https://google.com",
					},
					{
					  number: '14',
					  parm: '-s',
					  depict: '-s参数将不输出错误和进度信息。-S参数指定只输出错误信息，通常与-o一起使用。',
					  instance: "curl -s https://www.example.com curl -s -o /dev/null https://google.com",
					},
					{
					  number: '15',
					  parm: '-u',
					  depict: '-u参数用来设置服务器认证的用户名和密码。',
					  instance: "curl -u 'zhangsan:123456' https://google.com/login 或者 curl https://zhangsan:12345@google.com/login",
					},
					{
					  number: '16',
					  parm: '-v',
					  depict: '-v参数输出通信的整个过程，用于调试。',
					  instance: "curl -v https://www.example.com",
					},
					{
					  number: '17',
					  parm: '--trace',
					  depict: '--trace参数也可以用于调试，还会输出原始的二进制数据。',
					  instance: "curl --trace - https://www.example.com",
					},
					{
					  number: '18',
					  parm: '-x',
					  depict: '-x参数指定 HTTP 请求的代理,通过myproxy.com:8080的 socks5 代理发出。-X参数指定 HTTP 请求的方法。',
					  instance: "curl -x socks5://james:cats@myproxy.com:8080 https://www.example.com curl -X POST https://www.example.com",
					}]
		
	})
	moduleaction(httpData.value.moduleid,1);
		
	let generate = () => {
	   var tempCurl="curl ";
	   tempCurl = curlAddRequestParm(tempCurl);
	   tempCurl = 
	   tempCurl=tempCurl+" -H "+"'"+"Content-Type: "+httpData.value.contentType+";"+" charset="+httpData.value.encoded+"'"
	   tempCurl=tempCurl+setAuthAndCookie();
	   var url=httpData.value.url;
	   if(httpData.value.request=="GET"){
	   tempCurl = tempCurl+" "+ url
	   var getHeader = setGetHeader(httpData.value.header)
			if(url.lastIndexOf("?") < 0 && getHeader){
				tempCurl=tempCurl+"?"+getHeader;
			}
	   }else{
		var postHeader = setPostHeader(httpData.value.header);
		var bodyData = httpData.value.bodyData;
		if(bodyData){
			tempCurl =tempCurl + " -d " + "'"+bodyData+"'";
		}
		tempCurl = tempCurl+postHeader+ " " + url
	   }
	   httpData.value.curl=tempCurl;
	   moduleaction(httpData.value.moduleid,2);
	}
	
	
	let curlAddRequestParm = (curl) => {//eslint-disable-line
		if(httpData.value.parameter.includes("1")){
					curl = curl+"-i ";
		}
		if(httpData.value.parameter.includes("2")){
					curl = curl+"-v ";
		}
		if(httpData.value.parameter.includes("3")){
				curl = curl+"-o result ";
		}
		return curl+"-X " + httpData.value.request;
	}
	
	let setGetHeader = (headerParmList) => {
		var header="";
		if(httpData.value.type.includes("1")){
			for(var i in headerParmList){
				var key=headerParmList[i].key;
				var value=headerParmList[i].value;
				if(i>0&&i<headerParmList.length-1){
				header=header+"\\&"	
				}
				if(key && value){
				header=header+key + "=" +value;	
				}
			}
		}
		return header;
	}
	
	let setPostHeader = (headerParmList) => {
		var header="";
		if(httpData.value.type.includes("1")){
			for(var i in headerParmList){
				var key=headerParmList[i].key;
				var value=headerParmList[i].value;
				if(key && value){
				header =header+ " -H " + "'"+ key +":"+ value +"'";
				}
			}
		}
		return header;
	}
	
	let setAuthAndCookie = () => {
		var header="";
		if(httpData.value.type.includes("2")){
			var user=httpData.value.user;
			var password=httpData.value.password;
			if(user || password){
			header=header+" -u "+"'"+user+":"+password+"'";
			}
		}
		if(httpData.value.type.includes("3")){
			var cookie=httpData.value.cookie;
			if(cookie){
			header=header+" -b "+"'"+cookie+"'";
			}
		}
		return header;
	}
	
	/**
	 * 参数选择事件
	 */
	let typeChange = () => {
			if(httpData.value.type.includes("1")){
				httpData.value.headerShow=true
			}else{
				httpData.value.headerShow=false
			}
			if(httpData.value.type.includes("2")){
				httpData.value.authShow=true
			}else{
				httpData.value.authShow=false
			}
			if(httpData.value.type.includes("3")){
				httpData.value.cookieShow=true
			}else{
				httpData.value.cookieShow=false
			}
			generate();
		}
	
	/**
	 * 请求方式事件
	 */
	let requestChange = () => {
			if(httpData.value.request=="GET"){
				httpData.value.postDataShow=false
				httpData.value.contentType="text/xml"
				return;
			}
			
			if(httpData.value.request=="DELETE"){
				httpData.value.contentType="application/x-www-form-urlencoded"
			}else if(httpData.value.request==("POST" || "PUT")){
				httpData.value.contentType="application/json"
			}else{
				httpData.value.postDataShow=true
			}
			generate();
		}	
		
	/**
	 * 根据输入，动态添加行
	 */
	let headerInput = (index) => {
		if(httpData.value.header.length-1==index && httpData.value.header[index].key!=""){
			httpData.value.header.push({})
			}
			generate();
		}
		
	/**
	 * 根据下标，动态删除行
	 */
	let deleteInput = (index) => {
		httpData.value.header.splice(index, 1);
		generate();
		}
			
	/**
	 * 还原初始值
	 */
	let init = () => {
		httpData.value.url= "";
		httpData.value.request= "GET";
		httpData.value.encoded="UTF-8";
		httpData.value.contentType="text/xml";
		httpData.value.parameter= ["1"];
		httpData.value.type= ["1"];
		httpData.value.headerShow= true;
		httpData.value.header= [{}];
		httpData.value.bodyData= "";
		httpData.value.bodyDataShow= true;
		httpData.value.curlRequest= "";
		httpData.value.user= "";
		httpData.value.password= "";
		httpData.value.cookie= "";
		httpData.value.curl= "";
		httpData.value.authShow= false;
		httpData.value.cookieShow= false;
		}
		
</script>

<style>

</style>